import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyA2LCmaBOjBPpDi0BWLf4btPfuZDDxRTgs",
  authDomain: "molgys-lists-acbf2.firebaseapp.com",
  databaseURL: "https://molgys-lists-acbf2.firebaseio.com",
  projectId: "molgys-lists-acbf2",
  storageBucket: "molgys-lists-acbf2.appspot.com",
  messagingSenderId: "953624708303",
  appId: "1:953624708303:web:c9009b81414bbddf63a3ba",
  measurementId: "G-CX1SN01R8C"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
  }

  // Auth API
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () =>
    this.auth.signOut();

  doPasswordReset = email =>
    this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // User API
  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');
}

export default Firebase;