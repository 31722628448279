import React, { Component } from 'react'

class Landing extends Component {
  render() {
    return (
      <div>
        <h1>Landing Page</h1>
      </div>
    )
  }
}

export default Landing;